import React from "react"

import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import { Title } from "../atoms/Typography"

import Link from "../utils/Link"
import ThemeSwitcher from "../utils/ThemeSwitcher"

const AnnouncementBar = ({
  text,
  link,
  showAnnouncementBar,
  homepageOnly,
  theme,
  bg,
  shadow,
  lockedToTop,
  slug,
  isHomepage,
  menuOpen,
}) => {
  const showOnCurrentPage = homepageOnly
    ? showAnnouncementBar &&
      !menuOpen &&
      (isHomepage || slug === "" || slug === "/")
    : showAnnouncementBar && !menuOpen

  return (
    <>
      {!link && (
        <ThemeSwitcher theme={theme}>
          <Box
            bg={`background.${bg}`}
            boxShadow={shadow}
            width={"100%"}
            display={showOnCurrentPage ? "block" : "none"}
            position={lockedToTop ? "sticky" : "relative"}
            top={lockedToTop && 0}
            zIndex={10000}
          >
            <Flex
              justifyContent={"center"}
              px={{ _: "10%", md: "20%" }}
              py={6}
              width={"100%"}
            >
              <Title variant={"h2"} fontSize={"18px"}>
                {text}
              </Title>
            </Flex>
          </Box>
        </ThemeSwitcher>
      )}
      {link && (
        <ThemeSwitcher theme={theme}>
          <Link {...link}>
            <Box
              bg={`background.${bg}`}
              boxShadow={shadow}
              width={"100%"}
              display={showOnCurrentPage ? "block" : "none"}
              position={lockedToTop ? "sticky" : "relative"}
              top={lockedToTop && 0}
              zIndex={10000}
            >
              <Flex justifyContent={"center"} px={"20%"} py={6} width={"100%"}>
                <Title variant={"h2"} fontSize={"18px"}>
                  {text}
                </Title>
              </Flex>
            </Box>
          </Link>
        </ThemeSwitcher>
      )}
    </>
  )
}

export default AnnouncementBar

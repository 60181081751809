import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Box from "../atoms/Box"
import ProportionalBox from "../atoms/ProportionalBox"
import ThemeSwitcher from "../utils/ThemeSwitcher"

// Ratio = height / width, automatically changes height based on width
const Card = ({
  children,
  ratio,
  theme,
  useCard,
  boxShadow,
  borderRadius = "md",
  bg = 0,
  ...rest
}) => {
  const cardProps = {
    boxShadow,
    borderRadius,
    bg: `background.${bg}`,
  }

  if (!useCard) {
    return <Box {...rest}>{children}</Box>
  }

  if (ratio) {
    return (
      <ThemeSwitcher theme={theme}>
        <ProportionalBox ratio={ratio} {...rest} {...cardProps}>
          {children}
        </ProportionalBox>
      </ThemeSwitcher>
    )
  }

  return (
    <ThemeSwitcher theme={theme}>
      <Box {...rest} {...cardProps}>
        {children}
      </Box>
    </ThemeSwitcher>
  )
}

Card.strapiProps = {
  boxShadow: PropTypes.oneOf(["none", "sm", "md", "lg"]),
  borderRadius: PropTypes.oneOf(["none", "sm", "md", "lg"]),
  bg: PropTypes.number,
  theme: PropTypes.oneOf(["default", "light", "dark", "highlight"]),
  useCard: PropTypes.bool.isRequired,
}

Card.defaultProps = {
  borderRadius: "md",
  boxShadow: "md",
  overflow: "hidden",
  zIndex: 100, // added zIndex to ensure overflow works correctly on Safari
  bg: 0,
  theme: "default",
  useCard: true,
}

Card.propTypes = {
  ...Card.strapiProps,
  ...Box.propTypes,
}

export default Card

export const query = graphql`
  fragment Card on Strapi_ComponentAtomsCard {
    theme
    bg
    boxShadow
    borderRadius
    useCard
  }
`

import styled from "styled-components"
import { space, layout, color, compose } from "styled-system"
import propTypes from "@styled-system/prop-types"
import { shouldForwardProp } from "../../lib/styled-system/helpers"

/**
 * Button Element - generic <button> element that doesn't look like a themed <Button>
 */
const ButtonElement = styled("button").withConfig({ shouldForwardProp })(
  compose(space, layout, color)
)

ButtonElement.propTypes = {
  ...propTypes.layout,
}

export default ButtonElement

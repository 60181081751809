import styled from "styled-components"
import { system } from "styled-system"

const G = styled("g")`
  ${system({
    stroke: {
      property: "stroke",
      scale: "colors",
    },
  })}
`

export default G

import React, { useContext } from "react"
import { CSSTransition } from "react-transition-group"
import { ThemeContext } from "styled-components"

/*
 * Extends CSSTransition from react-transition-group
 * in order to use timeout durations that match the theme
 */
const Reveal = ({ children, timeout, ...rest }) => {
  // Get durations from theme
  const { durations } = useContext(ThemeContext)
  const themeTimeout = durations[timeout] || timeout

  return (
    <CSSTransition timeout={themeTimeout} {...rest}>
      {children}
    </CSSTransition>
  )
}

export default Reveal

import styled from "styled-components"
import { space, color, compose, flexbox } from "styled-system"
import { animation } from "../../lib/styled-system/system"

const Svg = styled("svg")`
  vertical-align: middle;
  ${compose(space, flexbox, color, animation)}
`

export default Svg

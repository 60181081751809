// Custom props using the system low-level utility
// https://styled-system.com/api/#system
import { system } from "styled-system"

// Custom "animation" properties to add to styled system.
export const animation = system({
  transitionDuration: {
    property: "transitionDuration",
    scale: "durations",
    defaultScale: ["0ms", "200ms", "500ms", "1000ms"],
    transform: (value, scale) => `${scale[value]}ms`,
  },
  animationDuration: {
    property: "animationDuration",
    scale: "durations",
    defaultScale: ["0ms", "200ms", "500ms", "1000ms"],
    transform: (value, scale) => `${scale[value]}ms`,
  },
  transitionTimingFunction: {
    property: "transitionTimingFunction",
    scale: "timingFunctions",
    defaultScale: {
      ease: "ease",
      "ease-in": "ease-in",
      "ease-out": "ease-out",
      "ease-in-out": "ease-in-out",
    },
  },
  animationTimingFunction: {
    property: "animationTimingFunction",
    scale: "timingFunctions",
    defaultScale: {
      ease: "ease",
      "ease-in": "ease-in",
      "ease-out": "ease-out",
      "ease-in-out": "ease-in-out",
    },
  },
  transitionProperty: {
    property: "transitionProperty",
  },
})

// Custom svg properties
export const svg = system({
  fill: {
    property: "fill",
    scale: "colors",
  },
  stroke: {
    property: "stroke",
    scale: "colors",
  },
})

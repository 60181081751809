import React from "react"
import MuiAlert from "@material-ui/lab/Alert"

import styled from "styled-components"
import css from "../../lib/styled-system/css"

const StyledAlert = styled(MuiAlert)`
  &.MuiAlert-root {
    ${css({ fontFamily: "body" })}
  }
`

const Alert = ({ style, options, message, close }) => {
  return (
    <StyledAlert severity={options?.type} style={style}>
      {message}
    </StyledAlert>
  )
}

export default Alert

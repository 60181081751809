import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Box from "../atoms/Box"
import { Label } from "../atoms/Typography"
import NavLink from "../atoms/NavLink"

const LinkList = ({ title, links, ...rest }) => {
  return (
    <Box {...rest}>
      {title && (
        <Label color="text" mb={4}>
          {title}
        </Label>
      )}
      {links?.map((link, i) => (
        <NavLink {...link} display="block" mb={4} key={i} />
      ))}
    </Box>
  )
}

LinkList.strapiProps = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape(NavLink.strapiProps)),
}

LinkList.propTypes = {
  ...LinkList.strapiProps,
}

export const query = graphql`
  fragment LinkList on Strapi_ComponentMoleculesLinkList {
    title
    links {
      ...Link
    }
  }
`

export default LinkList

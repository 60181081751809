import React, { useContext } from "react"
import { ThemeContext } from "styled-components"

import Svg from "../utils/Svg"
import Path from "../utils/Path"
import G from "../utils/SvgGroup"

import Caret from "../icons/Caret"

const SmallArrow = ({ color = "primary", ...rest }) => {
  let currentTheme = useContext(ThemeContext)
  switch (currentTheme.icons?.smallArrow) {
    case "fullArrow":
      return (
        <Svg
          height="11"
          viewBox="0 0 13 11"
          width="13"
          xmlns="http://www.w3.org/2000/svg"
          {...rest}
        >
          <G
            fill="none"
            fillRule="evenodd"
            stroke={color}
            strokeLinecap="square"
            strokeWidth="1.25"
            transform="translate(1 1)"
          >
            <Path d="m.351563 4.21875h9.140625" />
            <Path d="m5.9765625 0 4.21875 4.21875-4.21875 4.21875" />
          </G>
        </Svg>
      )
    default:
      return <Caret color={color} {...rest} />
  }
}

export default SmallArrow

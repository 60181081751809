import React, { useContext } from "react"
import { ThemeContext } from "styled-components"

import Svg from "../utils/Svg"
import SvgGroup from "../utils/SvgGroup"
import Path from "../utils/Path"

const Hamburger = ({ color, ...rest }) => {
  let currentTheme = useContext(ThemeContext)
  switch (currentTheme.icons?.hamburger) {
    case "twoLines":
      return (
        <Svg
          height="12"
          viewBox="0 0 24 12"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <SvgGroup
            fill="none"
            fillRule="evenodd"
            stroke={color || "primary"}
            strokeLinecap="square"
            strokeWidth="2"
          >
            <path d="m1 1.5h21.185185" />
            <path d="m1 10.5h21.185185" />
          </SvgGroup>
        </Svg>
      )
    default:
      return (
        <Svg
          {...rest}
          width="25"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            fill={color || "primary"}
            d="M0 0h25v2H0V0zM0 7h25v2H0V7zM0 14h25v2H0v-2z"
          />
        </Svg>
      )
  }
}

export default Hamburger

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Box from "../atoms/Box"

const StyledBox = styled(Box)`
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: ${(props) => props.$ratio};
  }
`

// Ratio = height / width, automatically changes height based on width
const ProportionalBox = ({ children, ratio, ...rest }) => {
  return (
    <StyledBox $ratio={`${ratio * 100}%`} {...rest}>
      <Box position="absolute" height="100%" width="100%">
        {children}
      </Box>
    </StyledBox>
  )
}

ProportionalBox.defaultProps = {
  overflow: "hidden",
  width: "100%",
  ratio: 0.5625,
}

ProportionalBox.propTypes = {
  ratio: PropTypes.number,
  ...Box.propTypes,
}

export default ProportionalBox

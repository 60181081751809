import React from "react"

// threeJS stuff
import * as THREE from "three"
import { OBJLoader } from "three-obj-mtl-loader"

import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import { shadow, color, compose } from "styled-system"
import css from "../../lib/styled-system/css"

import ThemeSwitcher from "../utils/ThemeSwitcher"
import Link from "../utils/Link"
import Image from "../utils/Image"

import Flex from "../atoms/Flex"
import Box from "../atoms/Box"
import { Title } from "../atoms/Typography"

export const StyledHeader = styled("header")(
  // Static styles
  { position: "relative" },
  // Static styles that are defined by the theme
  css({ height: 5, zIndex: "header" }),
  // Dynamic styles that can be changed by props
  compose(color, shadow)
)

class ThreeScene extends React.Component {
  constructor(props) {
    super(props)
    this.state = { objScale: 0.013 }
    // this.handleResize = this.handleResize.bind(this);
  }
  componentDidMount() {
    const width = 70
    const height = 70
    this.scene = new THREE.Scene()

    //Add Renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    this.renderer.setClearColor(0xffffff, 0)
    this.renderer.setSize(width, height)
    this.renderer.setPixelRatio(window.devicePixelRatio) // reduces edge blur
    this.mount.appendChild(this.renderer.domElement)

    //add Camera
    this.camera = new THREE.PerspectiveCamera(40, width / height, 0.1, 1000)
    this.camera.position.z = 20
    this.camera.position.y = 0 // adjusts camera height

    //LIGHTS
    this.scene.add(new THREE.AmbientLight(0xffffff))

    //Simple Box with WireFrame
    this.addModels()

    // three.js webgl - postprocessing

    this.renderScene()
    //start animation
    this.start()
  }

  addModels() {
    //Create Material First
    const material = new THREE.MeshPhongMaterial({
      color: 0x00bdff, // red (can also use a CSS color string here)
      //flatShading: true,
      shininess: 150,
      combine: THREE.MixOperation,
      reflectivity: 0.5,
    })
    //Load Object Now and Set Material
    var objLoader = new OBJLoader()
    objLoader.load(
      `https://wbcg-cors-anywhere.herokuapp.com/${this.props.obj}`,
      (object) => {
        this.objMesh = object
        var objBbox = new THREE.Box3().setFromObject(object) // bounding box created to shift object to be centered at (0, 0, 0) in scene

        // Geometry vertices centering to world axis
        var bboxCenter = objBbox.getCenter().clone()
        bboxCenter.multiplyScalar(-1)

        object.traverse(function (child) {
          if (child instanceof THREE.Mesh) {
            child.material = material
            child.geometry.translate(bboxCenter.x, bboxCenter.y, bboxCenter.z)
          }
        })

        objBbox.setFromObject(object) // Update the bounding box
        this.objMesh.scale.set(
          this.state.objScale,
          this.state.objScale,
          this.state.objScale
        )
        this.scene.add(this.objMesh)
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded")
      },
      // called when loading has errors
      (error) => {
        console.log("An error happened" + error)
      }
    )
  }

  componentWillUnmount() {
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
    // window.removeEventListener("resize", this.handleResize, false);
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId)
  }

  animate = () => {
    // -----Step 3--------
    //Rotate Models
    if (this.objMesh) this.objMesh.rotation.y -= 0.008 // update this to change rotation speed

    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
  }

  renderScene = () => {
    // render the composer to apply the post-processing bloom effect (i.e. glow)
    if (this.renderer) this.renderer.render(this.scene, this.camera)
    // comment out the composer and render the renderer instead if you want to remove the glow effect
    // and render the object with just its material properties
    //if (this.renderer) this.renderer.render(this.scene, this.camera);
  }

  render() {
    return (
      <div
        style={{ width: "100%", height: "100%" }}
        ref={(mount) => {
          this.mount = mount
        }}
      />
    )
  }
}

const HeaderRadian = ({
  theme = "default",
  bg = 0,
  shadow = "none",
  logo,
  rightImage,
  siteTitle,
}) => {
  const Logo = () => (
    <>
      {logo && (
        <Box width={{ _: 8, md: 9 }} height={{ _: 2, md: 3 }}>
          <Link to="/">
            {logo && (
              <Image
                {...logo}
                style={{ height: "100%" }}
                imgStyle={{
                  objectFit: "contain",
                  objectPosition: "left center",
                }}
              />
            )}
          </Link>
        </Box>
      )}
      {!logo && (
        <Link to="/">
          <Title variant="h1" as="span">
            {siteTitle}
          </Title>
        </Link>
      )}
    </>
  )

  return (
    <ThemeSwitcher theme={theme}>
      <StyledHeader bg={`background.${bg}`} boxShadow={shadow}>
        <Flex
          px={5}
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
        >
          <Logo />
          {rightImage?.url && (
            <Box
              height="70px"
              position="absolute"
              top={{ _: 1, md: 3 }}
              right={20}
              zIndex={5}
            >
              <ThreeScene obj={rightImage?.url} />
            </Box>
          )}
          {/* {rightImage && (
            <Box width={{ _: 8, md: 9 }} height={{ _: 2, md: 3 }}>
              <Image
                imgStyle={{
                  objectFit: "contain",
                  objectPosition: "right center",
                }}
                style={{ height: "100%" }}
                {...rightImage}
              />
            </Box>
          )} */}
        </Flex>
      </StyledHeader>
    </ThemeSwitcher>
  )
}

export default HeaderRadian

HeaderRadian.strapiProps = {
  logo: PropTypes.shape({
    url: PropTypes.string,
    alternativeText: PropTypes.string,
  }),
  rightImage: PropTypes.shape({
    url: PropTypes.string,
    alternativeText: PropTypes.string,
  }),
}

HeaderRadian.propTypes = {
  ...HeaderRadian.strapiProps,
  siteTitle: PropTypes.string.isRequired,
}

export const query = graphql`
  fragment HeaderRadian on Strapi_ComponentHeadersRadian {
    __typename
    logo {
      url
      alternativeText
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 208
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
    }
    rightImage {
      url
    }
  }
`

import React from "react"
import Svg from "../utils/Svg"
import Path from "../utils/Path"

const Caret = ({ color = "primary", direction, strokeWidth, ...rest }) => {
  let deg = 0
  switch (direction) {
    case "left":
      deg = 90
      break
    case "up":
      deg = 180
      break
    case "right":
      deg = 270
      break
    default:
      deg = 0
  }
  return (
    <Svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g fill="none" transform={`rotate(${deg},6,4)`}>
        <Path d="M.75.75h10v7h-10v-7z" />
        <Path
          d="M2.75 2.75l3 3 3-3"
          stroke={color}
          strokeWidth={strokeWidth || "1.5"}
          strokeLinecap="square"
        />
      </g>
    </Svg>
  )
}

export default Caret

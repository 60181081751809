// https://github.com/rebassjs/grid
import styled from "styled-components"
import {
  background,
  border,
  color,
  compose,
  flexbox,
  layout,
  position,
  shadow,
  space,
  typography,
} from "styled-system"
import { animation } from "../../lib/styled-system/system"
import propTypes from "@styled-system/prop-types"
import { shouldForwardProp } from "../../lib/styled-system/helpers.js"

const Box = styled("div").withConfig({ shouldForwardProp, displayName: "Box" })(
  compose(
    animation,
    background,
    border,
    color,
    flexbox,
    layout,
    position,
    shadow,
    space,
    typography
  )
)

Box.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.flexbox,
  ...propTypes.border,
  ...propTypes.shadow,
  ...propTypes.typography,
}

export default Box

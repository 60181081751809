import React, { useContext } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import PropTypes from "prop-types"
import { mergeThemes } from "../../lib/styled-system/helpers"

const ThemeSwitcher = ({ theme, children }) => {
  let currentTheme = useContext(ThemeContext)

  if (theme !== "default" && currentTheme.themes[theme]) {
    const newTheme = mergeThemes(currentTheme, currentTheme.themes[theme])

    return <ThemeProvider theme={newTheme}>{children}</ThemeProvider>
  }
  return <>{children}</>
}

export default ThemeSwitcher

ThemeSwitcher.defaultProps = {
  theme: "default",
}

ThemeSwitcher.propTypes = {
  theme: PropTypes.oneOf(["default", "light", "dark", "highlight"]).isRequired,
  children: PropTypes.node.isRequired,
}

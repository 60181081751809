import { props as stprops } from "@styled-system/should-forward-prop"
import { merge, cloneDeep } from "lodash"
import propTypes from "@styled-system/prop-types"

// Prevent forwarding unnecessary props to HTML components
// https://github.com/styled-system/styled-system/issues/1253

// More custom props that we've defined in lib/styled-system/system
const customProps = [
  "transitionDuration",
  "animationDuration",
  "transitionTimingFunction",
  "animationTimingFunction",
  "transitionProperty",
]
const allProps = stprops.concat(customProps)
const regex = new RegExp(`^(${allProps.join("|")})$`)
export const shouldForwardProp = (prop, defaultValidatorFn) => {
  return !regex.test(prop)
}

const propsList = {}
Object.keys(propTypes).forEach(
  utility => (propsList[utility] = Object.keys(propTypes[utility]))
)
/*
 * Split Styled-System props so they can be applied to different components
 * Example Usages:
 *  const { space, rest } = splitProps(props, "space")
 *  const { space, typography, rest } = splitProps(props, ["space", "typography"])
 */
export const splitProps = (props, utilities) => {
  if (!props) return {}
  if (!utilities) return { rest: props }

  let propsCopy = cloneDeep(props)
  let obj = {}
  if (Array.isArray(utilities)) {
    utilities.forEach(utility => {
      obj[utility] = {}
      Object.keys(propsCopy).forEach(propKey => {
        if (propsList[utility].includes(propKey)) {
          obj[utility][propKey] = propsCopy[propKey]
          delete propsCopy[propKey]
        }
      })
    })
  } else {
    const utility = utilities
    obj[utility] = {}
    Object.keys(propsCopy).forEach(propKey => {
      if (propsList[utility].includes(propKey)) {
        obj[utility][propKey] = propsCopy[propKey]
        delete propsCopy[propKey]
      }
    })
  }
  obj.rest = propsCopy
  return obj
}

// Merge a new theme (theme2) into an existing theme (theme1).
// Theme 1 should be complete with all objects.
export const mergeThemes = (theme1, theme2) => {
  const newTheme = merge(cloneDeep(theme1), theme2)

  newTheme.breakpoints.forEach((breakpoint, i) => {
    switch (i) {
      case 0:
        newTheme.breakpoints.xs = breakpoint
        break
      case 1:
        newTheme.breakpoints.sm = breakpoint
        break
      case 2:
        newTheme.breakpoints.md = breakpoint
        break
      case 3:
        newTheme.breakpoints.lg = breakpoint
        break
      case 4:
        newTheme.breakpoints.xl = breakpoint
        break
      default:
        return
    }
  })

  return newTheme
}
